<template>
    <b-card class="st-section" id="application-details-target">
        <div class="st-section-body">
            <form class="form"
                  novalidate="novalidate"
                  id="st_application_details_form"
                  ref="st_application_details_form"
              >
                <div class="form-group">
                    <label>
                        {{ fields.application_type_id.label }} *
                    </label>
                    <st-select
                        v-model="model[fields.application_type_id.name]"
                        :ref="fields.application_type_id.name"
                        :field="fields.application_type_id"
                        :formValidation="fv"
                        autoSelectFirstOption
                    />
                </div>
            </form>
        </div>
    </b-card>
</template>
<script>
import { ApplicationModel } from "@/modules/applications/models/application-model";
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';

import { mapGetters } from "vuex";

const { fields } = ApplicationModel;

const formSchema = new FormSchema([
    fields.application_type_id,
]);
export default {
    name: "ApplicationDetailsForm",
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
        };
    },
    watch: {
        model: {
            deep: true,
            handler(val) {
                this.$emit('change', val);
            },
        },
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            currentUser: 'auth/currentUser',
        }),
    },
    created() {
        this.model = formSchema.initialValues({});
    },
    async mounted() {
        this.fv = createFormValidation('st_application_details_form', this.rules);
    },
}
</script>
